import 'swiper/css'
import 'swiper/css/pagination'

import styled from 'styled-components/macro'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { DefaultPageLayout } from '../app/layouts/Default'
import { FeaturingCollections, FlexBox, HorizontalLine } from '@bine-frontend-monorepo/uikit'
import { initializeApollo } from '../app/apollo-client'
import { useCollections } from '../app/hooks'

import { Item_OrderBy, MainPageDocument, MainPageQuery, OrderDirection } from '@data-access'

import { ExploreBineItems } from '../app/components'

import type { GetServerSidePropsContext, NextPage } from 'next'
import { getServerLocale } from '../app/helpers/getServerLocale'

const TRANSLATION_KEYS = ['common', 'homepage', 'header', 'footer']

const EXPLORE_ITEMS_COUNT = 60

const defaultVariables = {
  first: EXPLORE_ITEMS_COUNT,
  skip: 0,
  orderBy: Item_OrderBy.Id,
  orderDirection: OrderDirection.Desc,
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const client = initializeApollo()
  let pageData = {}

  try {
    const { data }: { data: MainPageQuery } = await client.query({
      query: MainPageDocument,
      variables: defaultVariables,
      fetchPolicy: 'no-cache',
    })

    console.log(
      `[DEBUG] Providing SSR data for MainPage. ${data.collections.length} collections and ${data.items.length} items`,
    )

    pageData = {
      collections: data?.collections,
      items: data?.items,
    }
  } catch (error) {
    console.error(error)

    pageData = {
      collections: [],
      items: [],
    }
  }
  return {
    props: {
      ...(await serverSideTranslations(getServerLocale(context), [...TRANSLATION_KEYS])),
      ...pageData,
    },
  }
}

const Heading = styled.h1`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 24px;
  line-height: 33px;
  margin: 0;
  margin-bottom: 6px;
  padding: 0;

  ${({ theme }) => theme.device.desktop} {
    font-size: 36px;
  }
`

const Subheading = styled.h3`
  font-family: 'Inter';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.deep_koamaru_medium_light};
  margin: 0;
  padding: 0;

  ${({ theme }) => theme.device.desktop} {
    font-size: 18px;

    br {
      display: none;
    }
  }
`

const StyledFlexBox = styled(FlexBox)`
  padding-top: 16px;
  margin-top: 0;
  margin-bottom: 0;

  gap: 0px;

  ${({ theme }) => theme.device.desktop} {
    padding-top: 16px;
    margin-top: 48px;
    margin-bottom: 30px;

    gap: 18px;
  }
`

const MainPage: NextPage<MainPageQuery> = ({ collections, items }) => {
  const { t, i18n } = useTranslation(TRANSLATION_KEYS)
  const featuredCollections = useCollections(collections)

  return (
    <DefaultPageLayout>
      <StyledFlexBox direction="column">
        <Heading>{t('homepage:heading')}</Heading>
        <Subheading>
          {t('homepage:subheading1')} <b>1000+</b> {t('homepage:subheading2')}
        </Subheading>
      </StyledFlexBox>
      <HorizontalLine margin="13px 0 0 0" />
      <FeaturingCollections collections={featuredCollections} />

      {/* <HorizontalLine margin="0 0 20px 0" />
      <GamesSection /> */}

      <HorizontalLine margin="30px 0" />
      <ExploreBineItems
        key={i18n.language}
        title={<h1>{t('explore')}</h1>}
        preloadedItems={items}
        variables={defaultVariables}
      />
    </DefaultPageLayout>
  )
}

export default MainPage
