import { ExploreItems, type SortType } from '@bine-frontend-monorepo/uikit'
import { useExploreItems, useItemQueryParams } from '../hooks'
import { useLazyQuery } from '@apollo/client'

import {
  ItemsOwnedQuery,
  ItemsOwnedQueryVariables,
  ItemsQuery,
  ItemsQueryVariables,
  ItemsDocument,
  ItemsOwnedDocument,
  GridItemFragment,
} from '@data-access'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'

type ExploreBineItemsProps = {
  preloadedItems?: GridItemFragment[]
  defaultSorting?: SortType
  variables: ItemsQueryVariables
  title: ReactNode | string
  styles?: React.CSSProperties
  owner?: string
}

export const ExploreBineItems = ({
  preloadedItems = [],
  defaultSorting = 'item_id',
  variables,
  title,
  styles,
  owner = null,
}: ExploreBineItemsProps) => {
  type AnyItemQuery = ItemsOwnedQuery | ItemsQuery
  type AnyItemQueryVariables = ItemsOwnedQueryVariables | ItemsQueryVariables
  const [loadData, { data, loading, fetchMore, refetch, called }] = useLazyQuery<AnyItemQuery, AnyItemQueryVariables>(
    typeof owner === 'string' ? ItemsOwnedDocument : ItemsDocument,
    { variables: { owner: owner, ...variables } },
  )
  const pageSize = useMemo(() => variables.first, [variables.first])
  const [expectedItemsCount, setExpectedItemsCount] = useState<number>(pageSize)
  const [loadingMore, setLoadingMore] = useState(false)

  const onChangeQueryParams = async (params: ItemsQueryVariables) => {
    setExpectedItemsCount(pageSize)
    setLoadingMore(true)
    try {
      if (called) {
        return await refetch({ owner: owner, ...params })
      } else {
        return await loadData({ variables: { owner: owner, ...params } })
      }
    } finally {
      setLoadingMore(false)
    }
  }

  const { queryParams, setQueryParams, refetching, queryParamsChanged } = useItemQueryParams({
    onChangeQueryParams,
    defaultFilters: variables.where,
  })

  const currentItems = useMemo(
    () => (queryParamsChanged && data?.items ? data?.items ?? [] : [...preloadedItems, ...(data?.items ?? [])]),
    [data, preloadedItems, queryParamsChanged],
  )
  const itemsForUI = useExploreItems(currentItems)

  useEffect(() => {
    // If we have preloaded items, we don't need to load more
    if (currentItems.length > 0) return

    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onLoadMore = async () => {
    if (loading) return

    const calcVariables = { owner: owner, ...variables, ...queryParams, skip: currentItems.length }
    setExpectedItemsCount(expectedItemsCount + calcVariables.first)
    setLoadingMore(true)

    try {
      if (called) {
        await fetchMore({
          variables: calcVariables,
          updateQuery(previousItems, options) {
            const newItems = options.fetchMoreResult?.items ?? []

            return {
              items: [...previousItems.items, ...newItems],
            }
          },
        })
      } else {
        await loadData({ variables: calcVariables })
      }
    } catch (error) {
      const errorMsg = `Error loading more items: ${error.message}`
      console.error(errorMsg)
      toast.error(errorMsg)
    } finally {
      setLoadingMore(false)
    }
  }

  return (
    <ExploreItems
      titleContent={title}
      items={itemsForUI as any} // TODO: fix this
      defaultSorting={defaultSorting}
      isLoading={loading || loadingMore}
      isRefetching={refetching}
      onLoadMore={onLoadMore}
      full={currentItems.length < expectedItemsCount && !loading && !loadingMore}
      onChangeQuery={setQueryParams}
      styles={styles}
    />
  )
}
