import styled from 'styled-components/macro'
import { GameCard } from '@bine-frontend-monorepo/uikit'

const StyledGamesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  margin-top: 20px;

  ${({ theme }) => theme.device.desktop} {
    grid-template-columns: repeat(4, 1fr);
  }
`

const Heading = styled.h1`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 24px;
  line-height: 33px;
  margin: 0;
  margin-bottom: 6px;
  padding: 0;
`

const Subheading = styled.h3`
  font-family: 'Inter';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.deep_koamaru_medium_light};
  margin: 0;
  padding: 0;
`

export const GamesSection = () => {
  return (
    <section>
      <Heading>Browse by games</Heading>
      <Subheading>Choose your game:</Subheading>
      <StyledGamesContainer>
        <GameCard
          bgSrc="https://i0.wp.com/www.unitedgamers.gg/wp-content/uploads/2022/07/Gods-Unchained.jpg?fit=1600%2C894&ssl=1"
          avatarSrc="https://via.placeholder.com/39/#6e55ab"
          title="Arcane Legends"
          subtitle="Search for the lost relics of the Arcane"
          genreText="RPG"
          genreColor="#F72585"
        />
        <GameCard
          bgSrc="https://playtoearndiary.com/wp-content/uploads/2022/08/Axie-Banner.jpg"
          avatarSrc="https://via.placeholder.com/39/#6e55ab"
          title="Axie Infinity"
          subtitle="Collect, breed, and battle with fantasy creatures"
          genreText="Card"
          genreColor="#F4AA65"
        />
        <GameCard
          bgSrc="https://cdn.akamai.steamstatic.com/steam/apps/570/header.jpg?t=1682639497"
          avatarSrc="https://cdn.akamai.steamstatic.com/steam/apps/570/header.jpg?t=1682639497"
          title="Dota 2"
          subtitle="A competitive game of action and strategy"
          genreText="MOBA"
          genreColor="#A565D7"
        />
        <GameCard
          bgSrc="https://cdn.akamai.steamstatic.com/steam/apps/730/header.jpg?t=1683566799"
          avatarSrc="https://via.placeholder.com/39/#6e55ab"
          title="Counter-Strike: Global Offensive"
          subtitle="The most played FPS game in the world"
          genreText="Shooter"
          genreColor="#86BE40"
        />
      </StyledGamesContainer>
    </section>
  )
}
